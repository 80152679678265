.ispace {
  margin: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.saStudioo {
  padding-bottom: 200px;
}

.saStudioo img {
  display: block;
  margin: 0 auto;
  /* width: 60%; */
  padding-bottom: 25px;
  -webkit-filter: drop-shadow(7px 7px 5px #000000);
  filter: drop-shadow(7px 7px 5px #000000);
}

/* .saStudioo {
  background-color: rgba(255, 0, 0, 0.1);
} */

@media screen and (min-width: 600px) {
  /* Screens above 600px width */
  .saStudioo img {
    display: block;
    margin: 0 auto;
    /* width: 40%; */
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 960px) {
  /* Screens above 960px width */
  .container {
    margin-top: 7%;
    padding-top: 0px;
  }
}
