i {
  color: #6197fb;
}
a {
  text-decoration: none;
  font-family: 'Special Elite', cursive;
}

body {
  background-color: #282c34;
}

btn,
button {
  -webkit-box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 5px 5px 5px 0px #000000, inset 4px 4px 15px 0px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.blockquote {
  font-family: 'Lobster', cursive;
}

.App {
  text-align: center;
  padding-top: 50px;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.bg-image {
  /* position: fixed; */
  background: #333 url('../src/images/pexels24.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.9;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
}

.brght {
  opacity: 0.9;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 70vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* padding-top: 5%; */
}

/* viewport height */

/* #welcome-section {
  height: 100vh;
} */

.App-link {
  color: #61dafb;
  /* color: #ffa500; */
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* scriptHammer logo */

.App-hammer {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 16em;
  margin: auto;
  /* margin-top: 3em; */
  overflow: hidden;
  background: rgba(51, 51, 51, 0) url(./images/script-n-hammer.png);
  /* background-size: 100% auto; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  -webkit-filter: drop-shadow(5px 5px 3px #000000);
  filter: drop-shadow(5px 5px 3px #000000);
}

.App-hammer img {
  display: block;
  width: 100%;
  /* height: auto; */
  animation: App-logo-spin infinite 20s linear;
}

.App-Lorem {
  /* margin-bottom: 300px; */
  padding-bottom: 300px;
  font-family: 'Lobster', cursive;
}

.App-Orange {
  color: #ffa500;
  /* font-family: 'Special Elite', cursive; */
  font-family: 'Alfa Slab One', cursive;

  /* font-family: 'Lobster', cursive; */

  /* font-family: 'Roboto', sans-serif; */

  /* font-family: 'Special Elite', cursive; */
}

.container {
  margin-top: 4%;
  /* padding-top: 45px; */
}

@media screen and (min-width: 600px) {
  /* Screens above 600px width */
  .container {
    /* margin-top: 12%; */
    padding-top: 0px;
  }
}

@media screen and (min-width: 960px) {
  /* Screens above 960px width */
  .container {
    /* margin-top: 12%; */
    padding-top: 0px;
  }
}
