/* Global Styles */
/* :root {
  --primary-color: #dc3545;
  --primary-color: green;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
} */

/* .btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
} */
body {
  padding-top: 15px;
  color: #6197fb;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
}
.lob {
  font-family: 'Lobster', cursive;
}

.navbar i {
  color: #6197fb;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.7rem 2rem; */
  z-index: 1;
  width: 100%;
  opacity: 0.97;
  /* margin-bottom: 1rem; */
  /* position: fixed; */
}
h3 {
  color: #61dafb;
}
.navbar ul {
  display: flex;
}

.navbar a {
  /* color: #fff; */
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* .active {
  color: orange;
} */
